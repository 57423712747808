.background-image {
  background: no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100%;
  min-height: 100vh;
}

.footer {
  position: fixed;
  height: 70px;
  bottom: 0;
  width: 100%;
}

a.btn {
  color: #002f5f;
}

.form-signin {
  width: 100%;
  max-width: 300px;
  background: #fff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  padding: 15px;
  margin: auto;
}

.form-forget {
  width: 100%;
  max-width: 400px;
  background: #fff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  padding: 15px;
  margin: auto;
}

.form-standard {
  width: 100%;
  max-width: 1000px;
  background: #fff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  padding: 15px;
  margin: auto;
}

/* Style the sidebar - fixed full height */
.sidebar {
  height: 100%;
  width: 60px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(78, 106, 136);
  overflow-x: hidden;
  padding-top: 16px;
}

/* Style sidebar links */
.sidebar a {
  padding: 6px 8px 6px 8px;
  text-decoration: none;
  font-size: 20px;
  color: rgb(191, 192, 192);
  display: block;
}

/* Style links on mouse-over */
.sidebar a:hover {
  color: rgb(255, 255, 255);
}

/* Style sidebar links */
.sidebar button {
  text-decoration: none;
  font-size: 20px;
  color: rgb(191, 192, 192);
  display: block;
}

/* Style links on mouse-over */
.sidebar button:hover {
  color: rgb(255, 255, 255);
}

.nav-item.active {
  background-color: rgb(255, 255, 255) !important;
  border: medium none;
  border-radius: 0;
}

ol.breadcrumb {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 6px;
}

.breadcrumb-item.active {
  font-weight: 700;
  font-style: oblique;
}

/* Style the main content */
.main {
  font-family: "Calibri", "sans-serif" !important;
  margin-left: 70px; /* Same as the width of the sidenav */
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: rgb(62, 99, 139) !important;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  border-top: 1px solid lightgrey;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: lightgrey !important;
  border-left: none;
  border-right: none;
  border-top: none;
}

.tab-content {
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  padding: 10px;
}

/* Add media queries for small screens (when the height of the screen is less than 450px, add a smaller padding and font-size) */
@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }
  .sidebar a {
    font-size: 18px;
  }
}
